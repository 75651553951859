import React, { useEffect, useState, useRef } from 'react';

// Graphs
import ApexCharts from 'apexcharts';


function AlertsChart() {
    const alertChart = useRef(null);
    let chartInstance = useRef(null);


    const [chart, setChart] = useState(null);
    const [data, setData] = useState([]);

    const [simUpCount, setSimUpCount] = useState(0);
    const [totalIterations, setTotalIterations] = useState(0);
    const [status, setStatus] = useState(null);


    useEffect(() => {
        const options = {
            chart: {
                type: 'donut',
                height: 230,
                background: '#242436',  // Match card background color
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 90,
                    offsetY: 10,
                },
            },
            grid: {
                padding: {
                    bottom: -80,
                },
            },
            colors: ['#28a745', '#dc3545', '#ffc107', '#007bff', '#17a2b8'], // Customize colors for dark theme
            labels: ['Total Alerts', 'Active Alerts', 'High Priority', 'Resolved', 'Other'],
            legend: {
                position: 'bottom',
                labels: {
                    colors: 'white',  // Text color for legend
                },
            },
            tooltip: {
                theme: 'dark',  // Dark theme for tooltips
            },
            series: [90, 29, 3, 5, 90],  // Example data
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],

        };


        try {
            if (!chartInstance.current) {
                chartInstance.current = new ApexCharts(alertChart.current, options);
                chartInstance.current.render();
            }
        } catch (error) {
            console.error('Error rendering chart:', error);
        }

        return () => {
            // if (chart.current) {
            //     chart.current.destroy();
            //     chart.current = null; // Clear the reference after destruction
            // }
        };
    }, [alertChart.current]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (chart) {
                const currentTime = new Date().toLocaleTimeString();
                const simUp = Math.random() > 0.1;
                const uptimeValue = 1; // Always set uptimeValue to 1
                const newDataPoint = {
                    x: currentTime,
                    y: uptimeValue,
                    downtime: calcPercent(),
                    fillColor: simUp ? '#2de377' : '#fc5842',
                };

                setStatus(simUp ? 'Operational' : 'Down');

                setData((prevData) => {
                    const updatedData = [...prevData.slice(-29), newDataPoint]; // Keep the array length at 30
                    chart.updateSeries([{ data: updatedData }]);
                    return updatedData;
                });

                // Update counts efficiently
                setTotalIterations((prev) => prev + 1);
                if (simUp) {
                    setSimUpCount((prev) => prev + 1);
                }
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [chart]);

    function calcPercent() {
        if (totalIterations === 0) return 0;
        return ((simUpCount / totalIterations) * 100).toFixed(2);
    }

    return (
        <div
            data-testid="alertChart"
            ref={alertChart}
            style={{
                height: '180px'
            }} />
    )
}
export default AlertsChart;