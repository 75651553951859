import React, { useState } from "react";

// Public Pages
import AboutUs from './pages/public/AboutUs';
import ContactUs from './pages/public/ContactUs';
import Controls from './pages/public/Controls';
import Home from './pages/public/Home';
import NewConstruction from './pages/public/NewConstruction';
import NoPage from './pages/public/NoPage';
import RMP from './pages/public/RMP';
import Service from './pages/public/Service';
import Training from './pages/public/Training';

// Protected Pages
// CST Tools
import Barcodes from './pages/protected/employee/Barcodes';
import Department from './pages/protected/employee/Department';
import Employees from './pages/protected/employee/Employees';
import InventoryCreator from './pages/protected/employee/InventoryCreator';
import InventoryDB from './pages/protected/employee/InventoryDB';
import Locations from './pages/protected/employee/Locations';
import Overview from './pages/protected/employee/Overview';
import Policies from './pages/protected/employee/Policies';
import Settings from './pages/protected/employee/Settings';
import Tasks from './pages/protected/employee/Tasks';
import Visual from './pages/protected/employee/Visual';
import WorkOrders from './pages/protected/employee/WorkOrders';

// Customer Tools
import CustomerHome from './pages/protected/customer/Home';
import CustomerSettings from './pages/protected/customer/Settings';
import Monitor from './pages/protected/customer/Monitor';
import Uptime from './pages/protected/customer/Uptime';
import Events from './pages/protected/customer/Events';
import Metrics from './pages/protected/customer/Metrics';
import Logs from './pages/protected/customer/Logs';

// Hooks
import CSTDrawer from "./hooks/CSTDrawer";
import CustomerDrawer from "./hooks/CustomerDrawer";
import Navbar from './hooks/Navbar';
import SignIn from './hooks/SignIn';

// Routes
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// Style
import './App.css';

function App() {
  const [isAuthenticated, setAuthStatus] = useState(false);
  const [isAuthenticatedCustomer, setCustomerStatus] = useState(false);

  /**
   * Callback function to receive data from the child component
   * @param {Boolean} data this handles the auth status
   */
  const handleAuthData = (data) => {
    setAuthStatus(data);
    console.log(isAuthenticated)
  };

  /**
   * Callback function to receive data from the child component
   * @param {Boolean} data this handles the auth status
   */
  const handleCustomerData = (data) => {
    setCustomerStatus(data);
    console.log(isAuthenticatedCustomer)
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/signin"
            element={<SignIn setAuthStatus={handleAuthData} setCustomerStatus={handleCustomerData} />}
          />
          <Route
            path="/"
            element={<Navbar />}>
            <Route
              index
              element={<Home />} />
            <Route
              path="/service"
              element={<Service />}
            />
            <Route
              path="/controls"
              element={<Controls />}
            />
            <Route
              path="/new-construction"
              element={<NewConstruction />}
            />
            <Route
              path="/rmp"
              element={<RMP />}
            />
            <Route
              path="/training"
              element={<Training />}
            />
            <Route
              path="/about"
              element={<AboutUs />}
            />
            <Route
              path="/contact"
              element={<ContactUs />}
            />
            <Route
              path="*"
              element={<NoPage />} />
          </Route>
          {isAuthenticatedCustomer ? (
            <Route
              path="/echo/*"
              element={
                <>
                  <CustomerDrawer />
                </>
              }>
              <Route
                path="home"
                element={
                  <CustomerHome />
                }
              />
              <Route
                path="monitor"
                element={
                  <Monitor />
                }
              />
              <Route
                path="uptime"
                element={
                  <Uptime />
                }
              />
              <Route
                path="events"
                element={
                  <Events />
                }
              />
              <Route
                path="metrics"
                element={
                  <Metrics />
                }
              />
              <Route
                path="logs"
                element={
                  <Logs />
                }
              />
              <Route
                path="settings"
                element={
                  <CustomerSettings />
                }
              />

            </Route>
          ) : (
            <Route
              path="/echo/*"
              element={<Navigate to="/signin" replace />} />
          )}
          {isAuthenticated ? (
            <Route
              path="/hoth/*"
              element={
                <>
                  <CSTDrawer />
                </>
              }>
              <Route
                path="overview"
                element={
                  <Overview />
                }
              />
              <Route
                path="locations"
                element={
                  <Locations />
                }
              />
              <Route
                path="visual"
                element={
                  <Visual />
                }
              />
              <Route
                path="inventory-creator"
                element={
                  <InventoryCreator />
                }
              />
              <Route
                path="inventory-db"
                element={
                  <InventoryDB />
                }
              />
              <Route
                path="work-orders"
                element={
                  <WorkOrders />
                }
              />
              <Route
                path="tasks"
                element={
                  <Tasks />
                }
              />
              <Route
                path="barcodes"
                element={
                  <Barcodes />
                }
              />
              <Route
                path="department"
                element={
                  <Department />
                }
              />
              <Route
                path="employees"
                element={
                  <Employees />
                }
              />
              <Route
                path="policies"
                element={
                  <Policies />
                }
              />
              <Route
                path="settings"
                element={
                  <Settings />
                }
              />
            </Route>
          ) : (
            <Route
              path="/hoth/*"
              element={<Navigate to="/signin" replace />} />
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
