import React, { useEffect, useRef } from 'react';

// Graphs
import ApexCharts from 'apexcharts';

function OutageChart() {
    const outageChart = useRef(null);
    let chartInstance = useRef(null);

    useEffect(() => {
        var options = {
            series: [{
                name: 'Total Outages',
                data: [44, 55, 41, 67, 22, 43]
            }, {
                name: 'Current Outages',
                data: [13, 23, 20, 8, 13, 27]
            }, {
                name: 'Average Downtime',
                data: [11, 17, 15, 15, 21, 14]
            }, {
                name: 'Longest Outage',
                data: [21, 7, 25, 13, 22, 8]
            }],
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    borderRadiusApplication: 'end', // 'around', 'end'
                    borderRadiusWhenStacked: 'last', // 'all', 'last'
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                color: "white",
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                type: 'datetime',
                categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
                    '01/05/2011 GMT', '01/06/2011 GMT'
                ],
                labels: {
                    style: {
                        colors: 'white' // Set x-axis text color to white
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: 'white' // Set y-axis text color to white
                    }
                }
            },
            tooltip: {
                theme: 'dark',  // Dark theme for tooltips
            },
            legend: {
                position: 'right',
                offsetY: 40,
                labels: {
                    colors: 'white'  // Set legend text color to white
                }
            },
            fill: {
                opacity: 1
            }
        };

        try {
            if (!chartInstance.current) {
                chartInstance.current = new ApexCharts(outageChart.current, options);
                chartInstance.current.render();
            }
        } catch (error) {
            console.error('Error rendering chart:', error);
        }

        return () => {
            // if (chart.current) {
            //     chart.current.destroy();
            //     chart.current = null; // Clear the reference after destruction
            // }
        };
    }, [outageChart])

    return (
        <div
            data-testid="outageChart"
            ref={outageChart}
            style={{
                height: '180px'
            }} />
    )
}
export default OutageChart;