import React, { useEffect } from 'react';

// Material UI Components
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// Axios API
import axiosInstance from "../utils/axiosInstance";

// React Redux
import { useSelector } from "react-redux";

function ProfilePanel() {
    const currentUser = useSelector((state) => state.currentUser);

    const [name, setName] = React.useState(currentUser?.user?.name || "");
    const [department, setDepartment] = React.useState(currentUser?.profile?.department || "");
    const [title, setTitle] = React.useState(currentUser?.profile?.title || "");
    const [description, setDescription] = React.useState(currentUser?.profile?.description || "");
    const [birthday, setBirthday] = React.useState(currentUser?.profile?.birthday || "");
    const [phone, setPhone] = React.useState(currentUser?.profile?.phone || "");
    const [isEditingName, setEditingName] = React.useState(false);
    const [isEditingDepartment, setEditingDepartment] = React.useState(false);
    const [isEditingTitle, setEditingTitle] = React.useState(false);
    const [isEditingDescription, setEditingDescription] = React.useState(false);
    const [isEditingBirthday, setEditingBirthday] = React.useState(false);
    const [isEditingPhone, setEditingPhone] = React.useState(false);

    useEffect(() => {

    }, [])
    // console.log(currentUser)

    const handleEditName = (event) => {
        setName(event.target.value);
    }
    const handleEditDepartment = (event) => {
        setDepartment(event.target.value);
    }
    const handleEditTitle = (event) => {
        setTitle(event.target.value);
    }
    const handleEditDescription = (event) => {
        setDescription(event.target.value);
    }
    const handleEditBirthday = (event) => {
        setBirthday(event.target.value);
    }
    const handleEditPhone = (event) => {
        setPhone(event.target.value);
    }

    const handleEditAccountName = () => {
        setEditingName(true)
    }

    const handleEditAccountDepartment = () => {
        setEditingDepartment(true)
    }

    const handleEditAccountTitle = () => {
        setEditingTitle(true)
    }

    const handleEditAccountDescription = () => {
        setEditingDescription(true)
    }

    const handleEditAccountBirthday = () => {
        setEditingBirthday(true)
    }

    const handleEditAccountPhone = () => {
        setEditingPhone(true)
    }

    const handleEditOff = () => {
        setEditingName(false);
        setEditingDepartment(false);
        setEditingTitle(false);
        setEditingDescription(false);
        setEditingBirthday(false);
        setEditingPhone(false);
    }

    // const dispatch = useDispatch();
    // const [profile, setProfile] = useState([]);

    const handleSave = () => {
        const updatedProfile = {
            name: name,
            department: department,
            title: title,
            description: description,
            birthday: birthday,
            phone: phone,
            email: currentUser?.profile?.email,
        };
        console.log(updatedProfile)

        axiosInstance.post("/users/update", updatedProfile)
            .then(response => {
                // Handle success, maybe update UI or give feedback
                console.log("Profile updated successfully:", response.data);
            })
            .catch(error => {
                // Handle error
                console.error("Error updating profile:", error);
            });
    };

    return (
        <Grid container>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    fullWidth
                    variant="outlined"
                    sx={{
                        borderRadius: "20px",
                    }}>
                    <CardContent>
                        <List>
                            <ListItem>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 500
                                    }}>Profile Picture:</Typography>
                                <Avatar
                                    alt={currentUser?.profile?.name}
                                    src={currentUser?.profile?.avatar}
                                    sx={{
                                        ml: 1,
                                        width: "60px",
                                        height: "60px"
                                    }} />
                            </ListItem>

                            <ListItem>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 500
                                    }}>Roles:</Typography>
                                {currentUser?.profile?.roles.map((role) => {
                                    return (<Chip label={role?.role_name}></Chip>)
                                })}
                            </ListItem>

                            <ListItem className="settingsHover">
                                {isEditingName ? (
                                    <TextField
                                        id="name-edit"
                                        label="Name:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditName}
                                        onBlur={handleEditOff}
                                        defaultValue={name}
                                        value={name}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Name:"
                                    fullWidth
                                    secondary={name}
                                    value={name}
                                    onClick={handleEditAccountName} />)}


                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingDepartment ? (
                                    <TextField
                                        id="department-edit"
                                        label="Department:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditDepartment}
                                        onBlur={handleEditOff}
                                        defaultValue={department}
                                        value={department}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Department:"
                                    fullWidth
                                    secondary={department}
                                    value={department}
                                    onClick={handleEditAccountDepartment} />)}

                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingTitle ? (
                                    <TextField
                                        id="title-edit"
                                        label="Title:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditTitle}
                                        onBlur={handleEditOff}
                                        defaultValue={title}
                                        value={title}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Title:"
                                    fullWidth
                                    secondary={title}
                                    value={title}
                                    onClick={handleEditAccountTitle} />)}

                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingDescription ? (
                                    <TextField
                                        id="description-edit"
                                        label="Description:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditDescription}
                                        onBlur={handleEditOff}
                                        multiline
                                        defaultValue={description}
                                        value={description}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Description:"
                                    fullWidth
                                    secondary={description}
                                    value={description}
                                    onClick={handleEditAccountDescription} />)}
                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingBirthday ? (
                                    <TextField
                                        id="birthday-edit"
                                        label="Birthday:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditBirthday}
                                        onBlur={handleEditOff}
                                        defaultValue={birthday}
                                        value={birthday}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Birthday:"
                                    fullWidth
                                    secondary={birthday}
                                    value={birthday}
                                    onClick={handleEditAccountBirthday} />)}

                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingPhone ? (
                                    <TextField
                                        id="phone-edit"
                                        label="Phone:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditPhone}
                                        onBlur={handleEditOff}
                                        defaultValue={phone}
                                        value={phone}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Phone:"
                                    fullWidth
                                    secondary={phone}
                                    value={phone}
                                    onClick={handleEditAccountPhone} />)}

                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Email:"
                                    secondary={currentUser?.profile?.email} />
                            </ListItem>
                        </List>
                    </CardContent>
                    <CardActions sx={{ padding: "30px" }}>
                        <Button variant="contained" onClick={handleSave}>Save</Button>

                    </CardActions>
                </Card>
            </Grid>
        </Grid>

    )
}

export default ProfilePanel;