import React, { useEffect, useState } from 'react';

// Material UI Components
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Axios API
import axiosInstance from "../../../utils/axiosInstance";
import MonitorChart from '../../../components/apex/MonitorChart';


/**
 * Monitor Component
 * @returns {JSX.Element} The rendered Monitor Component.
 */
function Monitor() {
    const equipmentList = [
        { name: "Equipment 1" },
        { name: "Equipment 2" },
        { name: "Equipment 3" }
    ]
    const [loading, setLoading] = useState(true);

    const [time, setGraphTime] = useState("24");

    const [status, setStatus] = useState(null);


    const [companyList, GetCompanies] = useState([]);
    const [facilityList, GetFacilities] = useState([]);
    const [systemList, GetSystems] = useState([]);

    const [company, setCompany] = React.useState(null);
    const [facilityName, setFacilityName] = React.useState(null);
    const [system, setSystem] = React.useState(null);

    const [systemName, setSystemName] = React.useState("");



    const companySearch = [];
    const facilitySearch = [];
    const systemSearch = [];

    const [searchQuery, setSearchQuery] = useState('');

    const [simUpCount, setSimUpCount] = useState(0);
    const [totalIterations, setTotalIterations] = useState(0);

    useEffect(() => {
        axiosInstance
            .get(`/companies`).then(response => {
                console.log(response.data);
                GetCompanies(response.data);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });
    }, [GetCompanies, GetFacilities])


    /**
      * Company Selection
      * @param {Event} e The event object
      * @param {String} value The name of the Company
      * @returns {Void}
  */
    const handleCompanyChange = (e, value) => {
        // console.log(e.target.value);
        setCompany(value);
        if (value !== "") {
            axiosInstance
                .get(`/facilities/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    GetFacilities(response.data);

                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    /**
      * Facility Update Submit
      * @param {Event} e The event object
      * @param {String} value The System Name
      * @returns {Void}
  */
    const handleFacilityChange = (e, value) => {
        setSystemName(value);

        if (value !== "" && company !== "") {
            axiosInstance
                .get(`/systems/${company}/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    GetSystems(response.data);
                    const system = response.data[0] ?? {};

                    // const facility = response.data[0] ?? {};

                    setFacilityName(system.facility_name ?? "");

                    setSystemName(system.system_name ?? "");
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    /**
        * System Change Name
        * @param {event} e The event object. 
        * @param {String} value The name of System. 
        * @returns {Void}
    */
    const handleSystemChange = (e, value) => {
        setSystem(value);
    }

    companyList?.map((company) => {
        companySearch.push(
            {
                label: company.company_name,
                companyObject: company
            })
    })


    facilityList?.map((facility) => {
        facilitySearch.push(
            {
                label: facility.facility_name,
                facilityObject: facility
            })
    })


    systemList?.map((facility) => {
        systemSearch.push(
            {
                label: facility.system_name,
                facilityObject: system
            })
    })

    const filteredCompanies = searchQuery ? companyList?.filter((word) => word.label.includes(searchQuery)) : companySearch
    const filteredFacilities = searchQuery ? facilityList?.filter((word) => word.label.includes(searchQuery)) : facilitySearch
    const filteredSystems = searchQuery ? systemList?.filter((word) => word.label.includes(searchQuery)) : systemSearch

    function graphTime(event) {
        setGraphTime(event.target.value);
    }

    function calcPercent() {
        if (totalIterations === 0) return 0;
        return ((simUpCount / totalIterations) * 100).toFixed(2);
    }

    return (
        <Grid
            container
            padding={2}
            spacing={5}
            sx={{
                background: "#141422"
            }}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    levation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        background: "#242436",
                        color: "white"
                    }}>
                    <CardContent>
                        <Grid container>
                            <Grid
                                size={{
                                    xs: 10
                                }}>
                                <Typography variant="h3">
                                    Monitor
                                </Typography>

                            </Grid>
                            <Grid
                                size={{
                                    xs: 2
                                }}>
                                <Select
                                    onChange={graphTime}
                                    sx={{
                                        background: "#e7f1fe",
                                        borderRadius: "15px",
                                    }}
                                    value={time}
                                    defaultValue="24">
                                    <MenuItem value="24h">
                                        Last 24 Hours
                                    </MenuItem>
                                    <MenuItem value="1w">
                                        Last Week
                                    </MenuItem>
                                    <MenuItem value="1m">
                                        Last Month
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={5}>
                            <Grid
                                size={{
                                    md: 4,
                                    xs: 4
                                }}>
                                <Typography variant='h3'>
                                    Company
                                </Typography>
                                <Autocomplete
                                    value={company}
                                    onChange={handleCompanyChange}
                                    disablePortal
                                    id="company-search"
                                    options={filteredCompanies.map((word) => word.label)}
                                    sx={{ width: "100%", background: "#e7f1fe", borderRadius: "15px" }}
                                    renderInput={(params) => <TextField {...params} required label="Search Companies" />}
                                />
                            </Grid>
                            <Grid
                                size={{
                                    md: 4,
                                    xs: 12
                                }}>
                                <Typography variant='h3'>
                                    Facility
                                </Typography>
                                <Autocomplete
                                    value={facilityName}
                                    onChange={handleFacilityChange}
                                    disablePortal
                                    id="facility-search"
                                    options={filteredFacilities.map((word) => word.label)}
                                    sx={{ width: "100%", background: "#e7f1fe", borderRadius: "15px" }}
                                    renderInput={(params) => <TextField {...params} required label="Search Facilities" />}
                                />
                            </Grid>
                            <Grid
                                size={{
                                    md: 4,
                                    xs: 12
                                }}>
                                <Typography variant='h3'>
                                    System
                                </Typography>
                                <Autocomplete
                                    value={systemName}
                                    onChange={handleSystemChange}
                                    disablePortal
                                    id="system-search"
                                    options={filteredSystems.map((word) => word.label)}
                                    sx={{ width: "100%", background: "#e7f1fe", borderRadius: "15px" }}
                                    renderInput={(params) => <TextField {...params} required label="Search Systems" />}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                {
                    <Card
                        elevation={0}
                        variant="outlined"
                        sx={{
                            background: status === 'Operational' ? '#2de377' : '#fc5842',
                            padding: "20px",
                        }}>
                        <Typography
                            variant='h3'
                            style={{
                                fontWeight: 800,
                                color: '#fff'
                            }}> {status}
                        </Typography>
                    </Card>}
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                {equipmentList.map((equipment) => {
                    return (
                        <Accordion>

                            <AccordionSummary
                                aria-controls="chart2-content"
                                id="chart2-header"
                                sx={{
                                    background: "#242436",
                                    color: "white"
                                }}
                            >
                                <Typography
                                    variant='h3'
                                    sx={{
                                        fontWeight: 800,
                                    }}>
                                    {equipment.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ background: "#242436" }}>
                                <Card
                                    elevation={0}
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '20px',
                                        background: "#242436",
                                        color: "white"
                                    }}
                                >

                                    <CardContent
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Container>

                                            <MonitorChart />
                                        </Container>
                                    </CardContent>
                                </Card>
                            </AccordionDetails>
                        </Accordion>

                    )
                })}
            </Grid>
        </Grid>
    );
}

export default Monitor;
