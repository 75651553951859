import React, { useEffect, useRef } from 'react';

// Graphs
import ApexCharts from 'apexcharts';

function StatusChart() {
    const statusChart = useRef(null);
    let chartInstance = useRef(null);


    useEffect(() => {
        const options = {
            chart: {
                type: 'radialBar',
                height: 230,
            },
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        margin: 5,
                        size: '10%',
                        background: 'transparent',
                        image: undefined,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        }
                    },
                    barLabels: {
                        enabled: true,
                        useSeriesColors: true,
                        offsetX: -8,
                        fontSize: '12px',
                        formatter: function (seriesName, opts) {
                            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                        },
                    },
                }
            },
            colors: ['#fc5842', '#2de377', '#FFFAA0', '#FFAC1C', '#1ab7ea'],
            labels: ['Down', 'Operational', 'Degraded', 'Issues', 'Active',],
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }],

            series: [5, 90, 29, 3, 90],

        };


        try {
            if (!chartInstance.current) {
                chartInstance.current = new ApexCharts(statusChart.current, options);
                chartInstance.current.render();
            }
        } catch (error) {
            console.error('Error rendering chart:', error);
        }

        return () => {
            // if (chart.current) {
            //     chart.current.destroy();
            //     chart.current = null; // Clear the reference after destruction
            // }
        };
    }, [statusChart]);

    return (
        <div
            data-testid="statusChart"
            ref={statusChart}
            style={{
                height: '180px'
            }} />
    )
}

export default StatusChart;