import React, { useEffect } from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';


import employeeData from "./employeeDirectory.json"

/**
    * Employees Component
    * @returns {JSX.Element} The rendered Employees Component.
*/
function Employees() {
    const employees = employeeData.employeeDirectory;


    useEffect(() => {

    }, [])
    return (
        <Grid
            container
            padding={2}
            spacing={2}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Employees
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant='outlined'
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Directory
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <Table
                        sx={{
                            minWidth: 650
                        }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Department</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Contact Name</TableCell>
                                <TableCell>Mobile Phone</TableCell>
                                <TableCell>Office Extension</TableCell>
                                <TableCell>e-mail</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                employees?.map((employee) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{employee.department}</TableCell>
                                            <TableCell>{employee.title}</TableCell>
                                            <TableCell>{employee.contact_name}</TableCell>
                                            <TableCell>{employee.mobile_phone}</TableCell>
                                            <TableCell>{employee.office_number}</TableCell>
                                            <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', whiteSpace: 'normal' }}>{employee.email}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default Employees;